.cover-image {
  max-height: 60px;
}

.navbar-dropdown-account {
  width: 16rem;
}

.no-arrow::after {
  display: none;
}

svg {
  margin-bottom: 3px;
}

.nav-link {
  font-size: 13px;
}

.react-datepicker-wrapper {
  all: unset;
}

.break-word {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 4px;
  border: 1px solid #cfcfcf;

  &:last-of-type {
    margin-right: 0;
  }
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 40px;
  grid-column-gap: 15px;
  width: 100%;
}

.item-image {
  width: auto;
  max-width: 100%;
  height: 150px;
}

.image-option {
  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
    text-align: center;
    border: 1px solid #cfcfcf;
    background-color: #ffffff;
  }
}

.profile {
  display: flex;
  flex-direction: row;

  .no-photo {
    height: 300px;
    width: 220px;
    background-color: #f8fafd;
    border: 1px solid rgba(231, 234, 243, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      height: 300px;
      width: 220px;
      object-fit: cover;
    }
  }
}
