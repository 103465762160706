@import "~react-datepicker/dist/react-datepicker.css";
@import '~react-toastify/dist/ReactToastify.css';
@import '~bootstrap/scss/functions';
@import 'theme';
@import 'front-dashboard/variables';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/utilities';
@import 'front-dashboard/utilities';
@import '~bootstrap/scss/bootstrap';
@import 'front-dashboard/front-dashboard';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import 'custom';
