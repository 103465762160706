/*------------------------------------
  Footer
------------------------------------*/

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: $footer-height;

  &:not([class*="container"]) {
    padding-right: $footer-padding-x;
    padding-left: $footer-padding-x;

    // Small Devices
    @include media-breakpoint-down(sm) {
      padding-right: $content-padding-x / 2;
      padding-left: $content-padding-x / 2;
    }
  }
}

.footer-height-offset {
  height: subtract(100vh, $footer-height);
}